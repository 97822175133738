import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IUserProfileCreate,
  IOptions,
  IServiceCreate,
  IServiceUpdate,
  IBatchDeny,
  IBatchRequestChange,
  IQuarterUpdate,
} from "@/interfaces";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetServices,
  commitSetServicesPagination,
  commitSetUser,
  commitSetAllBatches,
  commitSetAllBatchesPagination,
  commitSetQuarters,
  commitSetQuartersPagination,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import axios from "axios";

type MainContext = ActionContext<AdminState, State>;

function createUserFriendlyError(context: MainContext, error) {
  if (axios.isAxiosError(error)) {
    if (error.response?.data) {
      const data = error.response?.data as { detail: string };
      commitAddNotification(context, {
        content: data.detail ? data.detail : "An error occurred",
        color: "error",
      });
    } else {
      commitAddNotification(context, {
        content: "An unexpected error occurred",
        color: "error",
      });
    }
  }
}

export const actions = {
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    const loadingNotification = {
      content: "Saving",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully created",
        color: "success",
      });
      return response;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionGetServices(
    context: MainContext,
    payload: {
      search: string | null;
      options: IOptions | null;
      limit: number | null;
      skip: number | null;
      organizationId: number | null;
    },
  ) {
    const params = {};
    if (payload.options !== null) {
      const options = payload.options;
      if (options.itemsPerPage) {
        params["limit"] = options.itemsPerPage;
      }
      if (options.itemsPerPage && options.page) {
        params["skip"] = options.itemsPerPage * (options.page - 1);
      }
      if (options.sortBy) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc) {
        params["desc"] = options.sortDesc[0];
      }
    }
    if (payload.limit !== null && payload.skip !== null) {
      params["limit"] = payload.limit;
      params["skip"] = payload.skip;
    }
    if (payload.search) {
      params["search_term"] = payload.search;
    }
    if (payload.organizationId) {
      params["organization_id"] = payload.organizationId;
    }

    try {
      const response = await api.getServices(context.rootState.main.token, params);
      if (response) {
        commitSetServices(context, response.data.services);
        commitSetServicesPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateService(context: MainContext, payload: IServiceCreate) {
    const loadingNotification = {
      content: "Creating Service...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createService(context.rootState.main.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Service successfully created",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionGetService(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getService(context.rootState.main.token, payload.id);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateService(
    context: MainContext,
    payload: { id: number; service: IServiceUpdate },
  ) {
    const loadingNotification = {
      content: "Saving Changes...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateService(context.rootState.main.token, payload.id, payload.service),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Service successfully updated",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionGetAllBatches(
    context: MainContext,
    payload: {
      status: string | null;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.status) {
      params["status"] = payload.status;
    }

    try {
      const response = await api.getBatches(context.rootState.main.token, params);
      if (response) {
        commitSetAllBatches(context, response.data.batches);
        commitSetAllBatchesPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDenyBatch(
    context: MainContext,
    payload: { id: number; batch: IBatchDeny },
  ) {
    const loadingNotification = {
      content: "Saving Changes...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.denyBatch(context.rootState.main.token, payload.id, payload.batch),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Batch denied successfully",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionRequestBatchChange(
    context: MainContext,
    payload: { id: number; batch: IBatchRequestChange },
  ) {
    const loadingNotification = {
      content: "Saving Changes...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.requestBatchChange(
            context.rootState.main.token,
            payload.id,
            payload.batch,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Batch review submitted",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionApproveBatch(context: MainContext, payload: { id: number }) {
    const loadingNotification = {
      content: "Saving Changes...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.approveBatch(context.rootState.main.token, payload.id),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];

      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Batch approved successfully",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      createUserFriendlyError(context, error);
    }
  },
  async actionGetQuarters(
    context: MainContext,
    payload: {
      options: IOptions;
      published: boolean | null;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }

    if (payload.published !== null) {
      params["published"] = payload.published;
    }

    try {
      const response = await api.getQuarters(context.rootState.main.token, params);

      if (response.data) {
        commitSetQuarters(context, response.data.quarters);
        commitSetQuartersPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateQuarter(
    context: MainContext,
    payload: {
      id: number;
      quarterIn: IQuarterUpdate;
    },
  ) {
    try {
      const response = await api.updateQuarter(
        context.rootState.main.token,
        payload.id,
        payload.quarterIn,
      );

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetServices = dispatch(actions.actionGetServices);
export const dispatchCreateService = dispatch(actions.actionCreateService);
export const dispatchGetService = dispatch(actions.actionGetService);
export const dispatchUpdateService = dispatch(actions.actionUpdateService);
export const dispatchGetAllBatches = dispatch(actions.actionGetAllBatches);
export const dispatchDenyBatch = dispatch(actions.actionDenyBatch);
export const dispatchRequestBatchChange = dispatch(actions.actionRequestBatchChange);
export const dispatchApproveBatch = dispatch(actions.actionApproveBatch);
export const dispatchGetQuarters = dispatch(actions.actionGetQuarters);
export const dispatchUpdateQuarter = dispatch(actions.actionUpdateQuarter);
