import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AdminState } from "./state";

const getDefaultState: () => AdminState = () => ({
  users: [],
  services: [],
  servicesOptions: { page: 1, itemsPerPage: 10 },
  servicesPagination: { total: 0, offset: 0 },
  allBatches: [],
  allBatchesOptions: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["last_modified"],
    sortDesc: [true],
  },
  allBatchesPagination: { total: 0, offset: 0 },
  quarters: [],
  quartersOptions: { page: 1, itemsPerPage: 10 },
  quartersPagination: { total: 0, offset: 0 },
});

export const getAdminModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
