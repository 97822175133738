import Vue from "vue";
import Router from "vue-router";

import RouterComponent from "./components/RouterComponent.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        {
          path: "login",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
          path: "lost-password",
          component: () =>
            import(/* webpackChunkName: "lost-password" */ "./views/LostPassword.vue"),
        },
        {
          path: "reset-password",
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"
            ),
        },
        {
          path: "main",
          redirect: "main/reports",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            {
              path: "users",
              component: RouterComponent,
              redirect: "users/all",
              children: [
                {
                  name: "users",
                  path: "all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-users" */ "./views/main/users/Users.vue"
                    ),
                },
                {
                  name: "user-create",
                  path: "create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-users-create" */ "./views/main/users/CreateUser.vue"
                    ),
                },
                {
                  name: "user-edit",
                  path: "edit/:id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-users-edit" */ "./views/main/users/EditUser.vue"
                    ),
                },
                {
                  name: "user-details",
                  path: ":id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-users-details" */ "./views/main/users/User.vue"
                    ),
                },
              ],
            },
            {
              path: "submissions",
              redirect: "submissions/upload",
              component: RouterComponent,
              children: [
                {
                  name: "submissions",
                  path: "all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-submissions" */ "./views/main/submissions/Submissions.vue"
                    ),
                },
                {
                  name: "submission-upload",
                  path: "upload",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-submission-upload" */ "./views/main/submissions/SubmissionUpload.vue"
                    ),
                },
                {
                  name: "manual-submission",
                  path: "manual",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-submissions-manual-upload" */ "./views/main/submissions/ManualSubmission.vue"
                    ),
                },
                {
                  path: ":id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-submission-router" */ "./views/main/submissions/SubmissionRouter.vue"
                    ),
                  children: [
                    {
                      name: "submission-details",
                      path: "details",
                      props: true,
                      component: () =>
                        import(
                          /* webpackChunkName: "main-submission-details" */ "./views/main/submissions/Submission.vue"
                        ),
                    },
                    {
                      name: "edit-fidelity-rating",
                      path: "edit/:ratingId",
                      props: true,
                      component: () =>
                        import(
                          /* webpackChunkName: "main-submissions-fidelity-rating-edit" */ "./views/main/submissions/EditFidelityRating.vue"
                        ),
                    },
                  ],
                },

                {
                  path: "admin",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"
                    ),
                  children: [
                    {
                      name: "submissions-admin",
                      path: "all",
                      props: { allBatch: true },
                      component: () =>
                        import(
                          /* webpackChunkName: "admin-submissions" */ "./views/main/submissions/Submissions.vue"
                        ),
                    },
                    {
                      path: ":id",
                      props: { canReview: true },
                      component: () =>
                        import(
                          /* webpackChunkName: "main-submission-router" */ "./views/main/submissions/SubmissionRouter.vue"
                        ),
                      children: [
                        {
                          name: "submission-details-admin",
                          path: "details",
                          props: true,
                          component: () =>
                            import(
                              /* webpackChunkName: "admin-submission-details" */ "./views/main/submissions/Submission.vue"
                            ),
                        },
                        {
                          name: "admin-edit-fidelity-rating",
                          path: "edit/:ratingId",
                          props: true,
                          component: () =>
                            import(
                              /* webpackChunkName: "main-submissions-fidelity-rating-edit" */ "./views/main/submissions/EditFidelityRating.vue"
                            ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "reports",
              redirect: "reports/state-adherence",
              component: RouterComponent,
              children: [
                {
                  path: "state-adherence",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-reports-state-adherence" */ "./views/main/reports/StateAdherence.vue"
                    ),
                },
                {
                  path: "detailed-adherence",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-reports-filterable-adherence" */ "./views/main/reports/DetailedAdherence.vue"
                    ),
                },
              ],
            },
            {
              path: "services",
              component: RouterComponent,
              redirect: "services/all",
              children: [
                {
                  name: "services",
                  path: "all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-services" */ "./views/main/services/Services.vue"
                    ),
                },
                {
                  name: "service-create",
                  path: "create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-services-create" */ "./views/main/services/CreateService.vue"
                    ),
                },
                {
                  name: "service-edit",
                  path: "edit/:id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-services-edit" */ "./views/main/services/EditService.vue"
                    ),
                },
                {
                  name: "service-details",
                  path: ":id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-services-details" */ "./views/main/services/Service.vue"
                    ),
                },
              ],
            },
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              children: [
                {
                  name: "profile-view",
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "./views/main/profile/UserProfile.vue"
                    ),
                },
                {
                  name: "profile-edit",
                  path: "edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "./views/main/profile/UserProfileEdit.vue"
                    ),
                },
              ],
            },
            {
              path: "admin",
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"
                ),
              children: [
                {
                  name: "quarters",
                  path: "quarters",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-quarters-all" */ "./views/main/Quarters.vue"
                    ),
                },
              ],
            },
            {
              path: "state-intermediaries",
              component: RouterComponent,
              redirect: "state-intermediaries/all",
              children: [
                {
                  name: "state-intermediaries",
                  path: "all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries" */ "./views/main/state-intermediaries/StateIntermediaries.vue"
                    ),
                },
                {
                  name: "intermediary-create",
                  path: "create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries-create" */ "./views/main/state-intermediaries/CreateStateIntermediary.vue"
                    ),
                },
                {
                  name: "intermediary-edit",
                  path: "edit/:id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries-edit" */ "./views/main/state-intermediaries/EditStateIntermediary.vue"
                    ),
                },
                {
                  name: "intermediary-details",
                  path: ":id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries-details" */ "./views/main/state-intermediaries/StateIntermediary.vue"
                    ),
                },
                {
                  name: "intermediary-add-admins",
                  path: ":id/add-admins",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries-add-admins" */ "./views/main/state-intermediaries/AddAdminsIntermediary.vue"
                    ),
                },
                {
                  name: "intermediary-add-organizations",
                  path: ":id/add-organizations",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-state-admin-intermediaries-add-organizations" */ "./views/main/state-intermediaries/AddOrganizationsIntermediary.vue"
                    ),
                },
              ],
            },
            {
              path: "organizations",
              component: RouterComponent,
              redirect: "organizations/all",
              children: [
                {
                  name: "organizations",
                  path: "all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations" */ "./views/main/organizations/Organizations.vue"
                    ),
                },
                {
                  name: "organization-create",
                  path: "create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations-create" */ "./views/main/organizations/CreateOrganization.vue"
                    ),
                },
                {
                  name: "organization-edit",
                  path: "edit/:id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations-edit" */ "./views/main/organizations/EditOrganization.vue"
                    ),
                },
                {
                  name: "organization-details",
                  path: ":id",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations-details" */ "./views/main/organizations/Organization.vue"
                    ),
                },
                {
                  name: "organization-add-admins",
                  path: ":id/add-admins",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations-add-admins" */ "./views/main/organizations/AddAdminsOrganization.vue"
                    ),
                },
                {
                  name: "organization-add-providers",
                  path: ":id/add-providers",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-organizations-add-providers" */ "./views/main/organizations/AddProvidersOrganization.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});
