import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileBasic,
  IUserProfileUpdate,
  IUserProfileCreate,
  IUsersWrapper,
  IStateIntermediariesWrapper,
  IStateIntermediaryUpdate,
  IStateIntermediaryCreate,
  IStateIntermediary,
  IOrganizationsWrapper,
  IOrganizationUpdate,
  IOrganization,
  IOrganizationCreate,
  IServicesWrapper,
  IServiceCreate,
  IService,
  IServiceUpdate,
  IRole,
  IDomain,
  IMultiSeriesData,
  ISingleSeriesData,
  ICounty,
  IBatchesWrapper,
  IBatch,
  IFidelityDetail,
  IBatchDeny,
  IBatchRequestChange,
  IFidelityCreate,
  IQuartersWrapper,
  IQuarter,
  IQuarterUpdate,
  IBatchUpdate,
} from "./interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async testToken(token: string) {
    return axios.post<IUserProfileBasic>(
      `${apiUrl}/api/v1/login/test-token`,
      {},
      authHeaders(token),
    );
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async getUsers(token: string, params) {
    return axios.get<IUsersWrapper>(`${apiUrl}/api/v1/users/`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getUsersForAccount(token: string, params) {
    return axios.get<IUsersWrapper>(`${apiUrl}/api/v1/users/account`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getUser(token: string, userId: number) {
    return axios.get<IUserProfile>(
      `${apiUrl}/api/v1/users/${userId}`,
      authHeaders(token),
    );
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async lostPassword(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getStateIntermediaries(token: string, params) {
    return axios.get<IStateIntermediariesWrapper>(
      `${apiUrl}/api/v1/state-intermediaries/`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  async updateStateIntermediary(
    token: string,
    id: number,
    data: IStateIntermediaryUpdate,
  ) {
    return axios.put<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/${id}`,
      data,
      authHeaders(token),
    );
  },
  async createStateIntermediary(token: string, data: IStateIntermediaryCreate) {
    return axios.post<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/`,
      data,
      authHeaders(token),
    );
  },
  async getStateIntermediary(token: string, id: number) {
    return axios.get<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/${id}`,
      authHeaders(token),
    );
  },
  async getOrganizations(token: string, payload) {
    let queryParams = "";
    if (payload.limit) {
      queryParams += `limit=${payload.limit}`;
    }
    if (payload.skip) {
      queryParams += `&skip=${payload.skip}`;
    }
    if (payload.sort_by) {
      queryParams += `&sort_by=${payload.sort_by}`;
    }
    if (payload.desc) {
      queryParams += `&desc=${payload.desc}`;
    }
    if (payload.search) {
      queryParams += `&search=${payload.search}`;
    }
    if (payload.year) {
      queryParams += `&year=${payload.year}`;
    }
    if (payload.adherence) {
      queryParams += `&adherence=${payload.adherence}`;
    }
    if (payload.service_ids) {
      queryParams += "&";
      queryParams += payload.service_ids.map((n) => `service_ids=${n}`).join("&");
    }
    return axios.get<IOrganizationsWrapper>(
      `${apiUrl}/api/v1/organizations/?${queryParams}`,
      authHeaders(token),
    );
  },
  async getOrganizationsForAccount(token: string, params) {
    return axios.get<IOrganizationsWrapper>(`${apiUrl}/api/v1/organizations/account`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async updateOrganization(token: string, id: number, data: IOrganizationUpdate) {
    return axios.put<IOrganization>(
      `${apiUrl}/api/v1/organizations/${id}`,
      data,
      authHeaders(token),
    );
  },
  async createOrganization(token: string, data: IOrganizationCreate) {
    return axios.post<IOrganization>(
      `${apiUrl}/api/v1/organizations/`,
      data,
      authHeaders(token),
    );
  },
  async getOrganization(token: string, id: number) {
    return axios.get<IOrganization>(
      `${apiUrl}/api/v1/organizations/${id}`,
      authHeaders(token),
    );
  },
  async getServices(token: string, params) {
    return axios.get<IServicesWrapper>(`${apiUrl}/api/v1/services/`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async createService(token: string, data: IServiceCreate) {
    return axios.post<IService>(`${apiUrl}/api/v1/services/`, data, authHeaders(token));
  },
  async getService(token: string, id: number) {
    return axios.get<IService>(`${apiUrl}/api/v1/services/${id}`, authHeaders(token));
  },
  async updateService(token: string, id: number, data: IServiceUpdate) {
    return axios.put<IService>(
      `${apiUrl}/api/v1/services/${id}`,
      data,
      authHeaders(token),
    );
  },
  async getRolesAssignable(token: string) {
    return axios.get<IRole[]>(`${apiUrl}/api/v1/roles/assignable`, authHeaders(token));
  },
  async getDomains(token: string) {
    return axios.get<IDomain[]>(`${apiUrl}/api/v1/domains/`, authHeaders(token));
  },
  async getOrganizationAdmins(token: string, id: number, params) {
    return axios.get<IUsersWrapper>(`${apiUrl}/api/v1/organizations/admins/${id}`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getOrganizationProviders(token: string, id: number, params) {
    return axios.get<IUsersWrapper>(`${apiUrl}/api/v1/organizations/providers/${id}`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getOrganizationProvidersBasic(token: string, id: number, params) {
    return axios.get<IUsersWrapper>(
      `${apiUrl}/api/v1/organizations/providers/basic/${id}`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  async addOrganizationAdmins(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IOrganization>(
      `${apiUrl}/api/v1/organizations/admins/add/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async removeOrganizationAdmins(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IOrganization>(
      `${apiUrl}/api/v1/organizations/admins/remove/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async addOrganizationProviders(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IOrganization>(
      `${apiUrl}/api/v1/organizations/providers/add/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async removeOrganizationProviders(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IOrganization>(
      `${apiUrl}/api/v1/organizations/providers/remove/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async addStateIntermediaryAdmins(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/admins/add/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async removeStateIntermediaryAdmins(token: string, id: number, user_ids: number[]) {
    let params = "";
    if (user_ids) {
      params = user_ids.map((n) => `user_ids=${n}`).join("&");
    }
    return axios.put<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/admins/remove/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async addStateIntermediaryOrganizations(
    token: string,
    id: number,
    organization_ids: number[],
  ) {
    let params = "";
    if (organization_ids) {
      params = organization_ids.map((n) => `organization_ids=${n}`).join("&");
    }
    return axios.put<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/organizations/add/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async removeStateIntermediaryOrganizations(
    token: string,
    id: number,
    organization_ids: number[],
  ) {
    let params = "";
    if (organization_ids) {
      params = organization_ids.map((n) => `organization_ids=${n}`).join("&");
    }
    return axios.put<IStateIntermediary>(
      `${apiUrl}/api/v1/state-intermediaries/organizations/remove/${id}?${params}`,
      {},
      authHeaders(token),
    );
  },
  async getBatchesForAccount(token: string, params) {
    return axios.get<IBatchesWrapper>(`${apiUrl}/api/v1/batches/me/`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getBatches(token: string, params) {
    return axios.get<IBatchesWrapper>(`${apiUrl}/api/v1/batches/`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getBatch(token: string, id: number) {
    return axios.get<IBatch>(`${apiUrl}/api/v1/batches/${id}`, authHeaders(token));
  },
  async updateBatch(token: string, id: number, batchUpdate: IBatchUpdate) {
    return axios.put<IBatch>(
      `${apiUrl}/api/v1/batches/${id}/update`,
      batchUpdate,
      authHeaders(token),
    );
  },
  async getFidelityDetails(token: string, fidelityRatingId: number) {
    return axios.get<IFidelityDetail[]>(
      `${apiUrl}/api/v1/fidelity_ratings/${fidelityRatingId}/fidelity_details`,
      authHeaders(token),
    );
  },
  async denyBatch(token: string, batchId: number, batchUpdate: IBatchDeny) {
    return axios.put<IBatch>(
      `${apiUrl}/api/v1/batches/${batchId}/deny`,
      batchUpdate,
      authHeaders(token),
    );
  },
  async requestBatchChange(
    token: string,
    batchId: number,
    batchUpdate: IBatchRequestChange,
  ) {
    return axios.put<IBatch>(
      `${apiUrl}/api/v1/batches/${batchId}/request_change`,
      batchUpdate,
      authHeaders(token),
    );
  },
  async approveBatch(token: string, batchId: number) {
    return axios.put<IBatch>(
      `${apiUrl}/api/v1/batches/${batchId}/approve`,
      {},
      authHeaders(token),
    );
  },
  async getStatewideDataQuarterly(token: string, params) {
    return axios.get(
      `${apiUrl}/api/v1/fidelity_ratings/reports/statewide/quarterly/${params.year}`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  async getStatewideDataYearly(token: string, params) {
    return axios.get<ISingleSeriesData>(
      `${apiUrl}/api/v1/fidelity_ratings/reports/statewide/yearly/${params.year}`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  async getStatewideServices(token: string, year: number) {
    return axios.get<string[]>(
      `${apiUrl}/api/v1/fidelity_ratings/reports/statewide/services/${year}`,
      authHeaders(token),
    );
  },
  async getReportQuarterly(
    token: string,
    payload: {
      year: number;
      adherence: string;
      service_ids: number[] | null;
      county_ids: number[] | null;
      organization_ids: number[] | null;
      provider_ids: number[] | null;
    },
  ) {
    let queryParams = "";
    queryParams += `year=${payload.year}`;
    queryParams += `&adherence=${payload.adherence}`;
    if (payload.service_ids) {
      queryParams += "&";
      queryParams += payload.service_ids.map((n) => `service_ids=${n}`).join("&");
    }
    if (payload.county_ids) {
      queryParams += "&";
      queryParams += payload.county_ids.map((n) => `county_ids=${n}`).join("&");
    }
    if (payload.provider_ids) {
      queryParams += "&";
      queryParams += payload.provider_ids.map((n) => `provider_ids=${n}`).join("&");
    }
    if (payload.organization_ids) {
      queryParams += "&";
      queryParams += payload.organization_ids
        .map((n) => `organization_ids=${n}`)
        .join("&");
    }
    return axios.get<IMultiSeriesData>(
      `${apiUrl}/api/v1/fidelity_ratings/reports/quarterly?${queryParams}`,
      authHeaders(token),
    );
  },
  async submitRatings(token: string, payload) {
    return axios.post(`${apiUrl}/api/v1/fidelity_ratings/submit`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getSubmissionTemplate(token: string) {
    return axios.get(`${apiUrl}/api/v1/fidelity_ratings/submission_template`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getAllCounties(token: string) {
    return axios.get<ICounty[]>(`${apiUrl}/api/v1/counties/all`, authHeaders(token));
  },
  async getCounties(token: string, payload) {
    let queryParams = "";
    if (payload.year) {
      queryParams += `year=${payload.year}`;
    }
    if (payload.adherence) {
      queryParams += `&adherence=${payload.adherence}`;
    }
    if (payload.service_ids) {
      queryParams += "&";
      queryParams += payload.service_ids.map((n) => `service_ids=${n}`).join("&");
    }
    return axios.get<ICounty[]>(
      `${apiUrl}/api/v1/counties/?${queryParams}`,
      authHeaders(token),
    );
  },
  async getFidelityRatingYears(token: string) {
    return axios.get<number[]>(
      `${apiUrl}/api/v1/fidelity_ratings/years`,
      authHeaders(token),
    );
  },
  async submitRatingsManual(
    token: string,
    data: {
      rating: IFidelityCreate;
      batch_id: number | undefined;
      batch_name: string | undefined;
    },
  ) {
    return axios.post(
      `${apiUrl}/api/v1/fidelity_ratings/submit/manual`,
      data,
      authHeaders(token),
    );
  },
  async getSubmissionDateRanges(token: string) {
    return axios.get<[string, string][]>(
      `${apiUrl}/api/v1/fidelity_ratings/submission_date_ranges`,
      authHeaders(token),
    );
  },
  async getQuarters(token: string, params) {
    return axios.get<IQuartersWrapper>(`${apiUrl}/api/v1/quarters/`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async updateQuarter(token: string, id: number, data: IQuarterUpdate) {
    return axios.put<IQuarter>(
      `${apiUrl}/api/v1/quarters/${id}`,
      data,
      authHeaders(token),
    );
  },
};
