import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { getMainModule } from "./main";
import { State } from "./state";
import { getAdminModule } from "./admin";
import { getStateAdminModule } from "./state-admin";
import { getOrganizationModule } from "./organization";
import { getBatchModule } from "./batch";

Vue.use(Vuex);

export const createStoreOptions = () => ({
  modules: {
    main: getMainModule(),
    admin: getAdminModule(),
    stateAdmin: getStateAdminModule(),
    organization: getOrganizationModule(),
    batch: getBatchModule(),
  },
});

const storeOptions: StoreOptions<State> = createStoreOptions();

export const store = new Vuex.Store<State>(storeOptions);

export default store;
