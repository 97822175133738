import { IOptions, IBatch, IPagination } from "@/interfaces";
import { batchState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setBatches(state: batchState, payload: IBatch[]) {
    state.batches = payload;
  },
  setBatchesPagination(state: batchState, payload: IPagination) {
    state.batchesPagination = payload;
  },
  setBatchesOptions(state: batchState, payload: IOptions) {
    state.batchesOptions = payload;
  },
};

const { commit } = getStoreAccessors<batchState, State>("");

export const commitSetBatches = commit(mutations.setBatches);
export const commitSetBatchesPagination = commit(mutations.setBatchesPagination);
export const commitSetBatchesOptions = commit(mutations.setBatchesOptions);
