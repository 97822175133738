import {
  IUserProfile,
  IService,
  IPagination,
  IOptions,
  IBatch,
  IQuarter,
} from "@/interfaces";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  setServices(state: AdminState, payload: IService[]) {
    state.services = payload;
  },
  setServicesPagination(state: AdminState, payload: IPagination) {
    state.servicesPagination = payload;
  },
  setServicesOptions(state: AdminState, payload: IOptions) {
    state.servicesOptions = payload;
  },
  setAllBatches(state: AdminState, payload: IBatch[]) {
    state.allBatches = payload;
  },
  setAllBatchesPagination(state: AdminState, payload: IPagination) {
    state.allBatchesPagination = payload;
  },
  setAllBatchesOptions(state: AdminState, payload: IOptions) {
    state.allBatchesOptions = payload;
  },
  setQuarters(state: AdminState, payload: IQuarter[]) {
    state.quarters = payload;
  },
  setQuartersPagination(state: AdminState, payload: IPagination) {
    state.quartersPagination = payload;
  },
  setQuartersOptions(state: AdminState, payload: IOptions) {
    state.quartersOptions = payload;
  },
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetServices = commit(mutations.setServices);
export const commitSetServicesPagination = commit(mutations.setServicesPagination);
export const commitSetServicesOptions = commit(mutations.setServicesOptions);
export const commitSetAllBatches = commit(mutations.setAllBatches);
export const commitSetAllBatchesPagination = commit(mutations.setAllBatchesPagination);
export const commitSetAllBatchesOptions = commit(mutations.setAllBatchesOptions);
export const commitSetQuarters = commit(mutations.setQuarters);
export const commitSetQuartersPagination = commit(mutations.setQuartersPagination);
export const commitSetQuartersOptions = commit(mutations.setQuartersOptions);
