import { organizationState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  organizations: (state: organizationState) => state.organizations,
  organizationsPagination: (state: organizationState) => state.organizationsPagination,
  organizationsOptions: (state: organizationState) => state.organizationsOptions,
  organizationAdmins: (state: organizationState) => state.organizationAdmins,
  organizationAdminsPagination: (state: organizationState) =>
    state.organizationAdminsPagination,
  organizationAdminsOptions: (state: organizationState) =>
    state.organizationAdminsOptions,
  organizationProviders: (state: organizationState) => state.organizationProviders,
  organizationProvidersPagination: (state: organizationState) =>
    state.organizationProvidersPagination,
  organizationProvidersOptions: (state: organizationState) =>
    state.organizationProvidersOptions,
};

const { read } = getStoreAccessors<organizationState, State>("");

export const readOrganizations = read(getters.organizations);
export const readOrganizationsPagination = read(getters.organizationsPagination);
export const readOrganizationsOptions = read(getters.organizationsOptions);
export const readOrganizationAdmins = read(getters.organizationAdmins);
export const readOrganizationAdminsPagination = read(
  getters.organizationAdminsPagination,
);
export const readOrganizationAdminsOptions = read(getters.organizationAdminsOptions);
export const readOrganizationProviders = read(getters.organizationProviders);
export const readOrganizationProvidersPagination = read(
  getters.organizationProvidersPagination,
);
export const readOrganizationProvidersOptions = read(
  getters.organizationProvidersOptions,
);
