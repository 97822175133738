import { api } from "@/api";
import {
  IOptions,
  IUserProfileUpdate,
  IUploadError,
  IFidelityCreate,
  IAdherence,
} from "@/interfaces";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import axios, { AxiosError } from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetBarChartData,
  commitSetCounties,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetStatewideLineData,
  commitSetStatewidePieData,
  commitSetToken,
  commitSetUserProfile,
  commitSetUsers,
  commitSetUsersPagination,
  commitTestTokenIntervalHandle,
} from "./mutations";
import { AppNotification, MainState } from "./state";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        dispatchPollToken(context);
        commitAddNotification(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  actionPollToken(context: MainContext) {
    if (context.state.testTokenIntervalHandle === null) {
      const testTokenIntervalHandle = window.setInterval(
        dispatchTestToken,
        60000,
        context,
      );
      commitTestTokenIntervalHandle(context, testTokenIntervalHandle);
    }
  },
  actionStopPollingToken(context: MainContext) {
    if (context.state.testTokenIntervalHandle) {
      window.clearInterval(context.state.testTokenIntervalHandle);
      commitTestTokenIntervalHandle(context, null);
    }
  },
  async actionTestToken(context: MainContext) {
    try {
      await api.testToken(context.state.token);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
          dispatchPollToken(context);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
    dispatchStopPollingToken(context);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: "Logged out", color: "success" });
  },
  actionRouteLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        await dispatchLogOut(context);
      }
    }
  },
  actionRouteLoggedIn() {
    if (router.currentRoute.path === "/login" || router.currentRoute.path === "/") {
      if ("next" in router.currentRoute.query && router.currentRoute.query.next) {
        router.push({ path: router.currentRoute.query.next as string });
      } else {
        router.push("/main");
      }
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async actionLostPasswordEmail(context: MainContext, payload: { email: string }) {
    try {
      await Promise.all([
        api.lostPassword(payload.email),
        await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      await dispatchLogOut(context);
    } catch (error) {
      const err = error as AxiosError | Error;
      await dispatchCheckApiError(context, err);
      if (err instanceof AxiosError) {
        const status = err.response?.status;
        const detail = err.response?.data?.detail ? err.response.data.detail : "";

        if (
          status === 404 &&
          detail === "The user with this email does not exist in the system."
        ) {
          return "That email is not registered to an account.";
        } else {
          return "An error occurred. Please check your input and try again.";
        }
      }
    }
  },
  async actionResetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    try {
      await Promise.all([
        api.resetPassword(payload.password, payload.token),
        await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      await dispatchLogOut(context);
    } catch (error) {
      const err = error as AxiosError | Error;
      await dispatchCheckApiError(context, err);
      if (err instanceof AxiosError) {
        const status = err.response?.status;
        const detail = err.response?.data?.detail ? err.response.data.detail : "";

        if (status === 400 && detail === "Invalid token") {
          return "That token was invalid. Try requesting another password reset email.";
        } else {
          return "An error occurred. Contact support for assistance.";
        }
      }
    }
  },
  async actionGetUsers(
    context: MainContext,
    payload: {
      search: string;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.search) {
      params["search"] = payload.search;
    }
    try {
      const response = await api.getUsers(context.rootState.main.token, params);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUsersForAccount(
    context: MainContext,
    payload: {
      search: string;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.search) {
      params["search"] = payload.search;
    }
    try {
      const response = await api.getUsersForAccount(
        context.rootState.main.token,
        params,
      );
      if (response) {
        commitSetUsers(context, response.data.users);
        commitSetUsersPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUser(
    context: MainContext,
    payload: {
      userId: number;
    },
  ) {
    try {
      const response = await api.getUser(context.state.token, payload.userId);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await api.updateUser(context.state.token, payload.id, payload.user);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetRolesAssignable(context: MainContext) {
    try {
      const response = await api.getRolesAssignable(context.state.token);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetDomains(context: MainContext) {
    try {
      const resp = await api.getDomains(context.state.token);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetStatewideDataYearly(
    context: MainContext,
    payload: { year: number; service_id: number | null },
  ) {
    try {
      const resp = await api.getStatewideDataYearly(context.state.token, payload);
      if (resp.data) {
        commitSetStatewidePieData(context, resp.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetStatewideServicesYearly(
    context: MainContext,
    payload: { year: number },
  ) {
    try {
      const resp = await api.getStatewideServices(context.state.token, payload.year);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetStatewideDataQuarterly(
    context: MainContext,
    payload: { year: number; service_id: number | null },
  ) {
    try {
      const resp = await api.getStatewideDataQuarterly(context.state.token, payload);
      if (resp.data) {
        commitSetStatewideLineData(context, resp.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetReportQuarterly(
    context: MainContext,
    payload: {
      year: number;
      adherence: string;
      service_ids: number[] | null;
      county_ids: number[] | null;
      organization_ids: number[] | null;
      provider_ids: number[] | null;
    },
  ) {
    try {
      commitSetBarChartData(context, { categories: [], data: [] });
      const resp = await api.getReportQuarterly(context.state.token, payload);
      if (resp.data) {
        commitSetBarChartData(context, resp.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSubmitRatings(context: MainContext, payload: { file: File }) {
    const genericError: IUploadError = {
      msg: "An unexpected error occurred",
    };
    try {
      const resp = await api.submitRatings(context.state.token, payload);
      if (resp) {
        commitAddNotification(context, {
          content: "Submission Successfully Uploaded",
          color: "success",
        });
        return "success";
      }
      return genericError;
    } catch (error) {
      const err = error as AxiosError | Error;
      await dispatchCheckApiError(context, err);
      if (err instanceof AxiosError && err.response?.data?.detail) {
        const data = err.response.data.detail as IUploadError;
        return data;
      }
      return genericError;
    }
  },
  async actionGetSubmissionTemplate(context: MainContext) {
    try {
      const resp = await api.getSubmissionTemplate(context.state.token);
      if (resp) {
        return resp;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCounties(
    context: MainContext,
    payload: {
      service_ids: number[] | null;
      adherence: IAdherence | null;
      year: number | null;
    },
  ) {
    try {
      const resp = await api.getCounties(context.state.token, payload);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAllCounties(context: MainContext) {
    try {
      const resp = await api.getAllCounties(context.state.token);
      if (resp.data) {
        commitSetCounties(context, resp.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetFidelityRatingYears(context: MainContext) {
    try {
      const resp = await api.getFidelityRatingYears(context.state.token);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSubmitRatingsManual(
    context: MainContext,
    payload: {
      rating: IFidelityCreate;
      batch_id: number | undefined;
      batch_name: string | undefined;
    },
  ) {
    const genericError: IUploadError = {
      msg: "An unexpected error occurred",
    };
    try {
      const resp = await api.submitRatingsManual(context.state.token, payload);
      if (resp) {
        commitAddNotification(context, {
          content: "Submission Successfully Uploaded",
          color: "success",
        });
        return "success";
      }
      return genericError;
    } catch (error) {
      const err = error as AxiosError | Error;
      await dispatchCheckApiError(context, err);
      if (err instanceof AxiosError && err.response?.data?.detail) {
        const data = err.response.data.detail as IUploadError;
        return data;
      }
      return genericError;
    }
  },
  async actionGetSubmissionDateRanges(context: MainContext) {
    try {
      const resp = await api.getSubmissionDateRanges(context.state.token);
      if (resp.data) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchTestToken = dispatch(actions.actionTestToken);
export const dispatchPollToken = dispatch(actions.actionPollToken);
export const dispatchStopPollingToken = dispatch(actions.actionStopPollingToken);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchLostPasswordEmail = dispatch(actions.actionLostPasswordEmail);
export const dispatchResetPassword = dispatch(actions.actionResetPassword);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUsersForAccount = dispatch(actions.actionGetUsersForAccount);
export const dispatchGetUser = dispatch(actions.actionGetUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetRolesAssignable = dispatch(actions.actionGetRolesAssignable);
export const dispatchGetDomains = dispatch(actions.actionGetDomains);
export const dispatchGetStatewideDataYearly = dispatch(
  actions.actionGetStatewideDataYearly,
);
export const dispatchGetStatewideServicesYearly = dispatch(
  actions.actionGetStatewideServicesYearly,
);
export const dispatchGetStatewideDataQuarterly = dispatch(
  actions.actionGetStatewideDataQuarterly,
);
export const dispatchGetReportQuarterly = dispatch(actions.actionGetReportQuarterly);
export const dispatchSubmitRatings = dispatch(actions.actionSubmitRatings);
export const dispatchGetSubmissionTemplate = dispatch(
  actions.actionGetSubmissionTemplate,
);
export const dispatchGetAllCounties = dispatch(actions.actionGetAllCounties);
export const dispatchGetCounties = dispatch(actions.actionGetCounties);
export const dispatchGetFidelityRatingYears = dispatch(
  actions.actionGetFidelityRatingYears,
);
export const dispatchSubmitRatingsManual = dispatch(actions.actionSubmitRatingsManual);
export const dispatchGetSubmissionDateRanges = dispatch(
  actions.actionGetSubmissionDateRanges,
);
