import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IAdherence,
  IOptions,
  IOrganizationCreate,
  IOrganizationUpdate,
} from "@/interfaces";
import { State } from "../state";
import { organizationState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetOrganizationAdmins,
  commitSetOrganizationAdminsPagination,
  commitSetOrganizationProviders,
  commitSetOrganizationProvidersPagination,
  commitSetOrganizations,
  commitSetOrganizationsPagination,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "@/store/main/mutations";
import axios from "axios";

type MainContext = ActionContext<organizationState, State>;

export const actions = {
  async actionGetOrganizations(
    context: MainContext,
    payload: {
      search: string | null;
      options: IOptions | null;
      limit: number | null;
      skip: number | null;
      service_ids: number[] | null;
      adherence: IAdherence | null;
      year: number | null;
    },
  ) {
    const params = {};
    if (payload.options !== null) {
      const options = payload.options;
      if (options.itemsPerPage) {
        params["limit"] = options.itemsPerPage;
      }
      if (options.itemsPerPage && options.page) {
        params["skip"] = options.itemsPerPage * (options.page - 1);
      }
      if (options.sortBy) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc) {
        params["desc"] = options.sortDesc[0];
      }
    }
    if (payload.limit !== null && payload.skip !== null) {
      params["limit"] = payload.limit;
      params["skip"] = payload.skip;
    }
    if (payload.search) {
      params["search"] = payload.search;
    }
    if (payload.service_ids) {
      params["service_ids"] = payload.service_ids;
    }
    if (payload.adherence) {
      params["adherence"] = payload.adherence;
    }
    if (payload.year) {
      params["year"] = payload.year;
    }

    try {
      const response = await api.getOrganizations(context.rootState.main.token, params);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrganizationsForAccount(
    context: MainContext,
    payload: {
      search: string | null;
      options: IOptions | null;
      limit: number | null;
      skip: number | null;
    },
  ) {
    const params = {};
    if (payload.options !== null) {
      const options = payload.options;
      if (options.itemsPerPage) {
        params["limit"] = options.itemsPerPage;
      }
      if (options.itemsPerPage && options.page) {
        params["skip"] = options.itemsPerPage * (options.page - 1);
      }
      if (options.sortBy) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc) {
        params["desc"] = options.sortDesc[0];
      }
    }
    if (payload.limit !== null && payload.skip !== null) {
      params["limit"] = payload.limit;
      params["skip"] = payload.skip;
    }
    if (payload.search) {
      params["search"] = payload.search;
    }

    try {
      const response = await api.getOrganizationsForAccount(
        context.rootState.main.token,
        params,
      );
      if (response) {
        commitSetOrganizations(context, response.data.organizations);
        commitSetOrganizationsPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateOrganization(
    context: MainContext,
    payload: { id: number; organization: IOrganizationUpdate },
  ) {
    const loadingNotification = {
      content: "Updating Organization...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = await api.updateOrganization(
        context.rootState.main.token,
        payload.id,
        payload.organization,
      );
      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Organization updated",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      if (axios.isAxiosError(error)) {
        const data = error.response?.data as { detail: string };
        commitAddNotification(context, {
          content: data.detail ? data.detail : "An error occurred",
          color: "error",
        });
      }
    }
  },
  async actionCreateOrganization(context: MainContext, payload: IOrganizationCreate) {
    const loadingNotification = {
      content: "Creating Organization...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = await api.createOrganization(
        context.rootState.main.token,
        payload,
      );
      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Organization created",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      if (axios.isAxiosError(error)) {
        const data = error.response?.data as { detail: string };
        commitAddNotification(context, {
          content: data.detail ? data.detail : "An error occurred",
          color: "error",
        });
      }
    }
  },
  async actionGetOrganization(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getOrganization(
        context.rootState.main.token,
        payload.id,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrganizationAdmins(
    context: MainContext,
    payload: {
      id: number;
      search: string | null;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.search) {
      params["search"] = payload.search;
    }

    try {
      const response = await api.getOrganizationAdmins(
        context.rootState.main.token,
        payload.id,
        params,
      );
      if (response) {
        commitSetOrganizationAdmins(context, response.data.users);
        commitSetOrganizationAdminsPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrganizationProviders(
    context: MainContext,
    payload: {
      id: number;
      search: string | null;
      options: IOptions | null;
      limit: number | null;
      skip: number | null;
    },
  ) {
    const params = {};
    if (payload.options !== null) {
      const options = payload.options;
      if (options.itemsPerPage) {
        params["limit"] = options.itemsPerPage;
      }
      if (options.itemsPerPage && options.page) {
        params["skip"] = options.itemsPerPage * (options.page - 1);
      }
      if (options.sortBy) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc) {
        params["desc"] = options.sortDesc[0];
      }
    }
    if (payload.limit !== null && payload.skip !== null) {
      params["limit"] = payload.limit;
      params["skip"] = payload.skip;
    }
    if (payload.search) {
      params["search"] = payload.search;
    }

    try {
      const response = await api.getOrganizationProviders(
        context.rootState.main.token,
        payload.id,
        params,
      );
      if (response) {
        commitSetOrganizationProviders(context, response.data.users);
        commitSetOrganizationProvidersPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetOrganizationProvidersBasic(
    context: MainContext,
    payload: {
      id: number;
      search: string | null;
      options: IOptions | null;
      limit: number | null;
      skip: number | null;
    },
  ) {
    const params = {};
    if (payload.options !== null) {
      const options = payload.options;
      if (options.itemsPerPage) {
        params["limit"] = options.itemsPerPage;
      }
      if (options.itemsPerPage && options.page) {
        params["skip"] = options.itemsPerPage * (options.page - 1);
      }
      if (options.sortBy) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc) {
        params["desc"] = options.sortDesc[0];
      }
    }
    if (payload.limit !== null && payload.skip !== null) {
      params["limit"] = payload.limit;
      params["skip"] = payload.skip;
    }
    if (payload.search) {
      params["search"] = payload.search;
    }

    try {
      const response = await api.getOrganizationProvidersBasic(
        context.rootState.main.token,
        payload.id,
        params,
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAddOrganizationAdmins(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const resp = await api.addOrganizationAdmins(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (resp) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRemoveOrganizationAdmins(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const resp = await api.removeOrganizationAdmins(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (resp) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAddOrganizationProviders(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const resp = await api.addOrganizationProviders(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (resp) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRemoveOrganizationProviders(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const resp = await api.removeOrganizationProviders(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (resp) {
        return resp.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<organizationState, State>("");

export const dispatchGetOrganizations = dispatch(actions.actionGetOrganizations);
export const dispatchGetOrganizationsForAccount = dispatch(
  actions.actionGetOrganizationsForAccount,
);
export const dispatchUpdateOrganization = dispatch(actions.actionUpdateOrganization);
export const dispatchCreateOrganization = dispatch(actions.actionCreateOrganization);
export const dispatchGetOrganization = dispatch(actions.actionGetOrganization);
export const dispatchGetOrganizationAdmins = dispatch(
  actions.actionGetOrganizationAdmins,
);
export const dispatchGetOrganizationProviders = dispatch(
  actions.actionGetOrganizationProviders,
);
export const dispatchGetOrganizationProvidersBasic = dispatch(
  actions.actionGetOrganizationProvidersBasic,
);
export const dispatchAddOrganizationAdmins = dispatch(
  actions.actionAddOrganizationAdmins,
);
export const dispatchRemoveOrganizationAdmins = dispatch(
  actions.actionRemoveOrganizationAdmins,
);
export const dispatchAddOrganizationProviders = dispatch(
  actions.actionAddOrganizationProviders,
);
export const dispatchRemoveOrganizationProviders = dispatch(
  actions.actionRemoveOrganizationProviders,
);
