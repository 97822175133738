import { stateAdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  stateIntermediaries: (state: stateAdminState) => state.stateIntermediaries,
  stateIntermediariesPagination: (state: stateAdminState) =>
    state.stateIntermediariesPagination,
  stateIntermediariesOptions: (state: stateAdminState) =>
    state.stateIntermediariesOptions,
};

const { read } = getStoreAccessors<stateAdminState, State>("");

export const readStateIntermediaries = read(getters.stateIntermediaries);
export const readStateIntermediariesPagination = read(
  getters.stateIntermediariesPagination,
);
export const readStateIntermediariesOptions = read(getters.stateIntermediariesOptions);
