import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile && state.userProfile.is_admin && state.userProfile.is_active
    );
  },
  hasIntermediaryAccess: (state: MainState) => {
    return (
      state.userProfile &&
      (state.userProfile.is_intermediary_admin || state.userProfile.is_admin) &&
      state.userProfile.is_active
    );
  },
  hasOrganizationAccess: (state: MainState) => {
    return (
      state.userProfile &&
      (state.userProfile.is_intermediary_admin ||
        state.userProfile.is_admin ||
        state.userProfile.is_organization_admin) &&
      state.userProfile.is_active
    );
  },
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  loginError: (state: MainState) => state.logInError,
  userProfile: (state: MainState) => state.userProfile,
  token: (state: MainState) => state.token,
  testTokenIntervalHandle: (state: MainState) => state.testTokenIntervalHandle,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  users: (state: MainState) => state.users,
  usersPagination: (state: MainState) => state.usersPagination,
  usersPageOptions: (state: MainState) => state.usersPageOptions,
  statewideLineData: (state: MainState) => state.statewideLineData,
  statewidePieData: (state: MainState) => state.statewidePieData,
  barChartData: (state: MainState) => state.barChartData,
  counties: (state: MainState) => state.counties,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasIntermediaryAccess = read(getters.hasIntermediaryAccess);
export const readHasOrganizationAccess = read(getters.hasOrganizationAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readTestTokenIntervalHandle = read(getters.testTokenIntervalHandle);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readUsers = read(getters.users);
export const readUsersPagination = read(getters.usersPagination);
export const readUsersPageOptions = read(getters.usersPageOptions);
export const readStatewideLineData = read(getters.statewideLineData);
export const readStatewidePieData = read(getters.statewidePieData);
export const readBarChartData = read(getters.barChartData);
export const readCounties = read(getters.counties);
