import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IOptions,
  IStateIntermediaryCreate,
  IStateIntermediaryUpdate,
} from "@/interfaces";
import { State } from "../state";
import { stateAdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetStateIntermediaries,
  commitSetStateIntermediariesPagination,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";

type MainContext = ActionContext<stateAdminState, State>;

export const actions = {
  async actionGetStateIntermediaries(
    context: MainContext,
    payload: {
      search: string | null;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.search) {
      params["search"] = payload.search;
    }

    try {
      const response = await api.getStateIntermediaries(
        context.rootState.main.token,
        params,
      );
      if (response) {
        commitSetStateIntermediaries(context, response.data.state_intermediaries);
        commitSetStateIntermediariesPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateStateIntermediary(
    context: MainContext,
    payload: { id: number; stateIntermediary: IStateIntermediaryUpdate },
  ) {
    try {
      await api.updateStateIntermediary(
        context.rootState.main.token,
        payload.id,
        payload.stateIntermediary,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateStateIntermediary(
    context: MainContext,
    payload: IStateIntermediaryCreate,
  ) {
    try {
      const response = await api.createStateIntermediary(
        context.rootState.main.token,
        payload,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetStateIntermediary(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getStateIntermediary(
        context.rootState.main.token,
        payload.id,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAddStateIntermediaryAdmins(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const response = await api.addStateIntermediaryAdmins(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRemoveStateIntermediaryAdmins(
    context: MainContext,
    payload: { id: number; user_ids: number[] },
  ) {
    try {
      const response = await api.removeStateIntermediaryAdmins(
        context.rootState.main.token,
        payload.id,
        payload.user_ids,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAddStateIntermediaryOrganizations(
    context: MainContext,
    payload: { id: number; organization_ids: number[] },
  ) {
    try {
      const response = await api.addStateIntermediaryOrganizations(
        context.rootState.main.token,
        payload.id,
        payload.organization_ids,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRemoveStateIntermediaryOrganizations(
    context: MainContext,
    payload: { id: number; organization_ids: number[] },
  ) {
    try {
      const response = await api.removeStateIntermediaryOrganizations(
        context.rootState.main.token,
        payload.id,
        payload.organization_ids,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<stateAdminState, State>("");

export const dispatchGetStateIntermediaries = dispatch(
  actions.actionGetStateIntermediaries,
);
export const dispatchUpdateStateIntermediary = dispatch(
  actions.actionUpdateStateIntermediary,
);
export const dispatchCreateStateIntermediary = dispatch(
  actions.actionCreateStateIntermediary,
);
export const dispatchGetStateIntermediary = dispatch(
  actions.actionGetStateIntermediary,
);
export const dispatchAddStateIntermediaryAdmins = dispatch(
  actions.actionAddStateIntermediaryAdmins,
);
export const dispatchRemoveStateIntermediaryAdmins = dispatch(
  actions.actionRemoveStateIntermediaryAdmins,
);
export const dispatchAddStateIntermediaryOrganizations = dispatch(
  actions.actionAddStateIntermediaryOrganizations,
);
export const dispatchRemoveStateIntermediaryOrganizations = dispatch(
  actions.actionRemoveStateIntermediaryOrganizations,
);
