import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  adminUsers: (state: AdminState) => state.users,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  services: (state: AdminState) => state.services,
  servicesPagination: (state: AdminState) => state.servicesPagination,
  servicesOptions: (state: AdminState) => state.servicesOptions,
  allBatches: (state: AdminState) => state.allBatches,
  allBatchesPagination: (state: AdminState) => state.allBatchesPagination,
  allBatchesOptions: (state: AdminState) => state.allBatchesOptions,
  quarters: (state: AdminState) => state.quarters,
  quartersPagination: (state: AdminState) => state.quartersPagination,
  quartersOptions: (state: AdminState) => state.quartersOptions,
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readServices = read(getters.services);
export const readServicesPagination = read(getters.servicesPagination);
export const readServicesOptions = read(getters.servicesOptions);
export const readAllBatches = read(getters.allBatches);
export const readAllBatchesPagination = read(getters.allBatchesPagination);
export const readAllBatchesOptions = read(getters.allBatchesOptions);
export const readQuarters = read(getters.quarters);
export const readQuartersPagination = read(getters.quartersPagination);
export const readQuartersOptions = read(getters.quartersOptions);
