import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { organizationState } from "./state";

const getDefaultState: () => organizationState = () => ({
  organizations: [],
  organizationsOptions: { page: 1, itemsPerPage: 10 },
  organizationsPagination: { total: 0, offset: 0 },
  organizationAdmins: [],
  organizationAdminsOptions: { page: 1, itemsPerPage: 10 },
  organizationAdminsPagination: { total: 0, offset: 0 },
  organizationProviders: [],
  organizationProvidersOptions: { page: 1, itemsPerPage: 10 },
  organizationProvidersPagination: { total: 0, offset: 0 },
});

export const getOrganizationModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
