import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MainState } from "./state";

const getDefaultState: () => MainState = () => ({
  isLoggedIn: null,
  token: "",
  testTokenIntervalHandle: null,
  logInError: false,
  userProfile: null,
  dashboardShowDrawer: true,
  notifications: [],
  users: [],
  usersPagination: { total: 0, offset: 0 },
  usersPageOptions: { page: 1, itemsPerPage: 5 },
  statewideLineData: { datasets: [], labels: [] },
  statewidePieData: { datasets: [], labels: [] },
  barChartData: { datasets: [], labels: [] },
  counties: [],
});

export const getMainModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
