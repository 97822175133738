import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true, // allows you to use theme colors in <style> blocks
    },
    themes: {
      light: {
        // primary colors
        primary: "#06186B", // Dark blue
        secondary: "#F0F4FF", // Pale blue
        accent: "#E24646", // Soft Red

        // misc colors
        error: "#E24646", // Soft Red
        warning: "#FFD100", // Yellow
        info: "#F0F4FF", // Pale blue
        disabled: "#A1A1A1", // light gray
        darkGray: "#292929", // dark gray
        medGray: "#595959", // Medium gray
        lightBlueGray: "#BABBC3", // light blue gray
        success: "#14A619", // Dark lime green
        lightBlue: "#4498D7", // Light blue
      },
    },
  },
});
