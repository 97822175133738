import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { batchState } from "./state";

const getDefaultState: () => batchState = () => ({
  batches: [],
  batchesOptions: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["last_modified"],
    sortDesc: [true],
  },
  batchesPagination: { total: 0, offset: 0 },
});

export const getBatchModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
