import { IOptions, IPagination, IStateIntermediary } from "@/interfaces";
import { stateAdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setStateIntermediaries(state: stateAdminState, payload: IStateIntermediary[]) {
    state.stateIntermediaries = payload;
  },
  setStateIntermediariesPagination(state: stateAdminState, payload: IPagination) {
    state.stateIntermediariesPagination = payload;
  },
  setStateIntermediariesOptions(state: stateAdminState, payload: IOptions) {
    state.stateIntermediariesOptions = payload;
  },
};

const { commit } = getStoreAccessors<stateAdminState, State>("");

export const commitSetStateIntermediaries = commit(mutations.setStateIntermediaries);
export const commitSetStateIntermediariesPagination = commit(
  mutations.setStateIntermediariesPagination,
);
export const commitSetStateIntermediariesOptions = commit(
  mutations.setStateIntermediariesOptions,
);
