import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { stateAdminState } from "./state";

const getDefaultState: () => stateAdminState = () => ({
  stateIntermediaries: [],
  stateIntermediariesOptions: { page: 1, itemsPerPage: 10 },
  stateIntermediariesPagination: { total: 0, offset: 0 },
});

export const getStateAdminModule = () => ({
  state: getDefaultState(),
  mutations,
  actions,
  getters,
});
