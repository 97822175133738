import { batchState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  batches: (state: batchState) => state.batches,
  batchesPagination: (state: batchState) => state.batchesPagination,
  batchesOptions: (state: batchState) => state.batchesOptions,
};

const { read } = getStoreAccessors<batchState, State>("");

export const readBatches = read(getters.batches);
export const readBatchesPagination = read(getters.batchesPagination);
export const readBatchesOptions = read(getters.batchesOptions);
