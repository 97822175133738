import { IOptions, IOrganization, IPagination, IUserProfile } from "@/interfaces";
import { organizationState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setOrganizations(state: organizationState, payload: IOrganization[]) {
    state.organizations = payload;
  },
  setOrganizationsPagination(state: organizationState, payload: IPagination) {
    state.organizationsPagination = payload;
  },
  setOrganizationsOptions(state: organizationState, payload: IOptions) {
    state.organizationsOptions = payload;
  },
  setOrganizationAdmins(state: organizationState, payload: IUserProfile[]) {
    state.organizationAdmins = payload;
  },
  setOrganizationAdminsPagination(state: organizationState, payload: IPagination) {
    state.organizationAdminsPagination = payload;
  },
  setOrganizationAdminsOptions(state: organizationState, payload: IOptions) {
    state.organizationAdminsOptions = payload;
  },
  setOrganizationProviders(state: organizationState, payload: IUserProfile[]) {
    state.organizationProviders = payload;
  },
  setOrganizationProvidersPagination(state: organizationState, payload: IPagination) {
    state.organizationProvidersPagination = payload;
  },
  setOrganizationProvidersOptions(state: organizationState, payload: IOptions) {
    state.organizationProvidersOptions = payload;
  },
};

const { commit } = getStoreAccessors<organizationState, State>("");

export const commitSetOrganizations = commit(mutations.setOrganizations);
export const commitSetOrganizationsPagination = commit(
  mutations.setOrganizationsPagination,
);
export const commitSetOrganizationsOptions = commit(mutations.setOrganizationsOptions);
export const commitSetOrganizationAdmins = commit(mutations.setOrganizationAdmins);
export const commitSetOrganizationAdminsOptions = commit(
  mutations.setOrganizationAdminsOptions,
);
export const commitSetOrganizationAdminsPagination = commit(
  mutations.setOrganizationAdminsPagination,
);
export const commitSetOrganizationProviders = commit(
  mutations.setOrganizationProviders,
);
export const commitSetOrganizationProvidersOptions = commit(
  mutations.setOrganizationProvidersOptions,
);
export const commitSetOrganizationProvidersPagination = commit(
  mutations.setOrganizationProvidersPagination,
);
