import { api } from "@/api";
import { ActionContext } from "vuex";
import { IBatchUpdate, IOptions } from "@/interfaces";
import { State } from "../state";
import { batchState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetBatches, commitSetBatchesPagination } from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

type MainContext = ActionContext<batchState, State>;

export const actions = {
  async actionGetBatchesForAccount(
    context: MainContext,
    payload: {
      status: string | null;
      options: IOptions;
    },
  ) {
    const params = {};
    const options = payload.options;
    if (options.itemsPerPage) {
      params["limit"] = options.itemsPerPage;
    }
    if (options.itemsPerPage && options.page) {
      params["skip"] = options.itemsPerPage * (options.page - 1);
    }
    if (options.sortBy) {
      params["sort_by"] = options.sortBy[0];
    }
    if (options.sortDesc) {
      params["desc"] = options.sortDesc[0];
    }
    if (payload.status) {
      params["status"] = payload.status;
    }

    try {
      const response = await api.getBatchesForAccount(
        context.rootState.main.token,
        params,
      );
      if (response) {
        commitSetBatches(context, response.data.batches);
        commitSetBatchesPagination(context, response.data.pagination);
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetBatch(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getBatch(context.rootState.main.token, payload.id);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateBatch(
    context: MainContext,
    payload: { batchId: number; batch: IBatchUpdate },
  ) {
    const loadingNotification = {
      content: "Saving Changes...",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateBatch(context.rootState.main.token, payload.batchId, payload.batch),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response.data) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: "Service successfully updated",
          color: "success",
        });
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Unable to save changes",
        color: "error",
      });
    }
  },
  async actionGetFidelityDetails(
    context: MainContext,
    payload: { fidelityRatingId: number },
  ) {
    try {
      const response = await api.getFidelityDetails(
        context.rootState.main.token,
        payload.fidelityRatingId,
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<batchState, State>("");

export const dispatchGetBatchesForAccount = dispatch(
  actions.actionGetBatchesForAccount,
);
export const dispatchGetBatch = dispatch(actions.actionGetBatch);
export const dispatchUpdateBatch = dispatch(actions.actionUpdateBatch);
export const dispatchGetFidelityDetails = dispatch(actions.actionGetFidelityDetails);
