import {
  IOptions,
  IPagination,
  IUserProfile,
  IMultiSeriesData,
  ISingleSeriesData,
  IDataSet,
  ICounty,
} from "@/interfaces";
import { MainState, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setTestTokenIntervalHandle(state: MainState, payload: number | null) {
    state.testTokenIntervalHandle = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload,
    );
  },
  setUsers(state: MainState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUsersPagination(state: MainState, payload: IPagination) {
    state.usersPagination = payload;
  },
  setUsersPageOptions(state: MainState, payload: IOptions) {
    state.usersPageOptions = payload;
  },
  setStatewideLineData(state: MainState, payload: IMultiSeriesData) {
    state.statewideLineData.labels = payload.categories;
    state.statewideLineData.datasets = payload.data;
  },
  setStatewidePieData(state: MainState, payload: ISingleSeriesData) {
    state.statewidePieData.labels = payload.labels;
    state.statewidePieData.datasets = [
      {
        label: "Statewide Fidelity Ratings",
        data: payload.data,
      } as IDataSet,
    ];
  },
  setBarChartData(state: MainState, payload: IMultiSeriesData) {
    state.barChartData.labels = payload.categories;
    state.barChartData.datasets = payload.data;
  },
  setCounties(state: MainState, payload: ICounty[]) {
    state.counties = payload;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitTestTokenIntervalHandle = commit(
  mutations.setTestTokenIntervalHandle,
);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetUsersPagination = commit(mutations.setUsersPagination);
export const commitSetUsersPageOptions = commit(mutations.setUsersPageOptions);
export const commitSetStatewideLineData = commit(mutations.setStatewideLineData);
export const commitSetStatewidePieData = commit(mutations.setStatewidePieData);
export const commitSetBarChartData = commit(mutations.setBarChartData);
export const commitSetCounties = commit(mutations.setCounties);
